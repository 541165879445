<template>
  <div>
    <vx-card actionable class="cardx" title="Delays(Level CM)">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <!-- <VueHotelDatepicker @confirm="getValue" /> -->
          <!-- <date-range-picker
          ref="picker"
          :opens="'right'"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
          style="width: 240px"
          @update="updateValues"
          ></date-range-picker>-->
          <datepicker placeholder="Select Date" v-model="dateRange" style="z-index: 1000"></datepicker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <v-select label="level" :options="levels" v-model="selected_level" :clearable=false
            style="width: 180px; z-index: 1000"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <v-select v-if="getrole('cm4')" label="cities" :options="city_options" v-model="selected_city"
            style="width: 240px; z-index: 1000"></v-select>
          <!-- Old-->
          <!-- <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            disabled
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>-->
          <!-- New-->
          <v-select v-if="getrole('cm1') || getrole('ac1') || getrole('exed')" disabled label="cities" :options="city_options"
            v-model="selected_city" style="width: 240px; z-index: 1000"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <v-select v-if="getrole('cm4')" label="City classification" :options="city_classification_list"
            v-model="selected_city_classification" style="width: 240px; z-index: 1000"></v-select>
          <v-select v-if="getrole('cm1') || getrole('ac1') || getrole('exed')" disabled label="City classification"
            :options="city_classification_list" v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-button color="dark" type="filled" @click="getSpocsWithCityClasification()">GO</vs-button>
        </vs-col>
      </vs-row>
      <vs-table :data="tableRows" v-if="level_value == 'M Levels'" style="
                            margin-top: 3%;
                            border-top: 1px solid;
                            border-left: 1px solid;
                            color: black;
                          " max-height="500px">
        <template slot="thead">
          <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan" style="border-right: 1px solid; position: sticky;
                            top: 0px; background-color: white;"
            :class="{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Level' }">{{
              head.header }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style"
            :class="indextr === 0 ? 'fixed_first_row_section' : ''">
            <vs-td class="fixed_columns_section" :style="tr.style"  >{{ tr.overall }}</vs-td>
            <vs-td class="fixed_header_level_section" :style="tr.style"  >{{ tr.level }}</vs-td>
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'allocated',
                  selected_city_classification
                )
              ">
                {{ tr.city.allocated }}
              </div>
            </vs-td>
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'delays',
                  selected_city_classification
                )
              ">
                {{ tr.city.delays }}
              </div>
            </vs-td>
            <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" style="border: 1px solid">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  [tr, spocIndex],
                  data[0].dynamicSpoc[spocIndex],
                  selected_city_classification
                )
              ">
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!-- U Level Table -->
      <CoverageULevelsTable v-if="level_value == 'U Levels'"></CoverageULevelsTable>
    </vx-card>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import VueApexCharts from "vue-apexcharts";
import EventBus from "../components/eventbus";
import CoverageULevelsTable from './CoverageULevelsTable.vue';
export default {
  data() {
    return {
      level_value: "M Levels",
      selected_spocs: [],
      selected_city_classification: "",
      city_classification_list: [],
      login_spoc_id:"",
      pieChart: {
        series: [],
        chartOptions: {
          legend: {
            show: false,
          },
          labels: [],
          colors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      name: "App",
      type: "margin",
      fromDate: null,
      toDate: null,
      spoc_names: [],
      select1: "",
      select2: "",
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      dateRange: new Date(),
      duration: [
        { text: "WTD", value: 0 },
        { text: "MTD", value: 1 },
        { text: "QTD", value: 2 },
        { text: "YTD", value: 3 },
      ],
      sort_by: [
        { text: "MWB Count", value: 0 },
        { text: "Enrollment", value: 1 },
        { text: "Visitors", value: 2 },
        { text: "Utilization", value: 3 },
        { text: "Delays", value: 4 },
        { text: "Due", value: 5 },
        { text: "SR%", value: 6 },
      ],
      selected_city: "",
      loggedin: "",
      city_options: [
          "Ahmedabad",
          "Bangalore",
          "Chennai",
          "Delhi",
          "Ernakulam",
          "Hyderabad",
          "Kolkata",
          "Mumbai",
          "Pune",
      ],
      cities: [],
      levels: ["M Levels", "U Levels"],
      selected_level: "M Levels",
      responseData: [],
      tablerowConstant: [
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid" ,background: "#ffffff" },
          overall: "",
          level: "",
        },
        // {
        //   style: { borderBottom: "1px solid", background: "rgb(244 230 255)" },
        //   overall: "P1 & P2 Total",
        //   level: "",
        // },
        {
          style: { background: "#ccffcc", },
          level: "M3",
          type: "P1",
        },
        { style: { background: "#ccffcc" }, level: "M3+", type: "P1" },
        { style: { background: "#ccffcc" }, level: "M3++", type: "P1" },

        {
          style: { background: "#ccffcc" },
          overall: "P1",
          level: "M5",
          type: "P1",
        },
        {
          style: { background: "#ccffcc" },
          level: "M6",
          rowspan: 4,
          type: "P1",
        },
        {
          style: { borderBottom: "1px solid", background: "#ccffcc" },
          overall: "Total",
          level: "P1",
          type: "P1 Total",
        },
        // {
        //   style: { background: "#ffffcc" },
        //   level: "L6",
        //   rowspan: 6,
        //   type: "P2"
        // },
        { style: { background: "#ffffcc" }, overall: "P2", level: "M4", type: "P2" },
        // {
        //   style: { background: "#ffffcc" },
        //   overall: "P2",
        //   level: "L4",
        //   type: "P2"
        // },
        { style: { background: "#ffffcc" }, level: "M2", type: "P2" },
        // { style: { background: "#ffffcc" }, level: "L3", type: "P2" },
        // { style: { background: "#ffffcc" }, level: "L2", type: "P2" },
        {
          style: { borderBottom: "1px solid", background: "#ffffcc" },
          overall: "Total",
          level: "P2",
          type: "P2 Total"
        },
        // {
        //   style: { background: "#ccffff", borderTop: "1px solid" },
        //   level: "L4-",
        //   rowspan: 2,
        //   type: "P3"
        // },
        // { style: { background: "#ccffff" }, level: "L1", type: "P3" },
        // {
        //   style: { background: "#ccffff" },
        //   overall: "P3",
        //   level: "M4",
        //   rowspan: 4,
        //   type: "P3",
        // },
        { style: { background: "#ccffff" }, overall: "P3", level: "M4-", type: "P3" },
        // { style: { background: "#ccffff" }, level: "M2", type: "P3" },
        { style: { background: "#ccffff" }, level: "M1", type: "P3" },
        {
          style: { borderBottom: "1px solid", background: "#ccffff" },
          overall: "Total",
          level: "P3",
          type: "P3 Total",
        },
        {
          style: { borderBottom: "1px solid", background: "#FFCCCC" },
          overall: "Grand Total",
          level: "",
        },
        //  { style: { background: "#ccffff" }, level: "L2-" },
        // {
        //   style: { background: "#f6d094", border: "1px solid" },
        //   overall: "M7",
        //   level: "M7",
        // },
        // {
        //   style: { background: "#cccccc", border: "1px solid" },
        //   overall: "N/A",
        //   level: ""
        // }
      ],
      tableHeaders: [],
      tableRows: [],
      users: [
        {
          id: 1,
          name: "Ismail",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 2,
          name: "Nida",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 3,
          name: "Anita",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 4,
          name: "Imran",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 5,
          name: "Jain",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
      ],
      newdata: [
        {
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "",
          total: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Maria: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Imran: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Ismail: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Anita: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
        },
        {
          color: "#FFCCCC",
          overall: {
            allocated: "",
            contacted: "Total",
            not_contacted: "",
          },
          level: "",
          total: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Maria: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Imran: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: "",
          level: "M6",
          total: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Maria: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Imran: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: {
            allocated: "",
            contacted: "P1",
            not_contacted: "",
          },
          level: "M5",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: "",
          level: "M3+",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "M3",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L2",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "L3",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "P2",
            not_contacted: "",
          },
          level: "L3+",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L4",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L5",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "L6",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFFF",
          overall: {
            allocated: "",
            contacted: "P3",
            not_contacted: "",
          },
          level: "L4-",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFFF",
          overall: "",
          level: "L1",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "M1",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: {
            allocated: "",
            contacted: "P4",
            not_contacted: "",
          },
          level: "M2",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: "",
          level: "M4-",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: "",
          level: "M4",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCCCCC",
          // overall: {
          //   allocated: "",
          //   contacted: "N/A",
          //   not_contacted: ""
          // },
          level: "",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
      ],
      citySelection: "",
    };
  },
  mounted() {
    // console.log("Hrrlo");
    this.login_spoc_id = localStorage.getItem('spoc_id');
    let team = localStorage.getItem('team');
    if(team == 'GM'){
      this.getGmReportingSpocs()
    }else{
      this.getSpocs();
    }
    EventBus.$on("updateCoverage", () => {
      this.updateValues();
    });
  },
  watch: {
    selected_city_classification(val) {
      this.selected_city_classification = val;
    },
    selected_city(val) {
      console.log("selcted city watch", val);
      this.getCityClasificationCodes(val);
    },
    dateRange() {
      // this.updateValues();
    },
  },
  methods: {
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          this.cities = [];
          if (response.data.spocs.length != 0) {
            // this.spoc_names = response.data.spocs
            let active_spocs = []
            response.data.spocs.forEach(element => {
              if (element.active == true) {
                active_spocs.push(element)
              }
            })
            this.spoc_names = active_spocs
            this.loggedin = localStorage.getItem('spoc_id');
            let duplicates = []
            response.data.spocs.forEach(element => {
              if (!duplicates.includes(element.city) && element.active == true) {
                this.cities.push(element.city)
                duplicates.push(element.city)
              }
            })
            console.log("active cities", this.cities);

            this.extractSpocByCity(this.spoc_names);
            return true
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocsWithCityClasification() {
      // let city = this.new_selected_city;
      console.log(this.selected_city);
      console.log(this.selected_city_classification);
      if(localStorage.getItem('team') != 'GM'){

      let url = `${constants.SERVER_API}getSpocsWithCityClasification?city=${this.selected_city}&city_clasification=${this.selected_city_classification}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.citySelection = this.selected_city;
          console.log("getSpocsWithCityClasification", response);
          this.selected_spocs = response.data.spocs;
          console.log("step1", this.selected_spocs);

          // this.getSpocIds(spoc_data);
          this.level_value = this.selected_level;
          this.updateValues();
        })
        .catch((error) => {
          this.handleError(error);
        });
      }else{
        this.citySelection = this.selected_city;
        console.log("spoc_names1", this.spoc_names);
        let city_wise_spocs = [];
        this.spoc_names.forEach(element => {
          if(element.city == this.selected_city && this.selected_city_classification == "All"){
            city_wise_spocs.push(element);
          }else{
            if(element.city_classification == this.selected_city_classification){
              city_wise_spocs.push(element);
            }
          }
        });
        console.log("spoc_names2", city_wise_spocs);
        this.selected_spocs = city_wise_spocs;
        this.level_value = this.selected_level;
        this.updateValues();
      }
    },
    getSpocIds(spoc_data) {
      let spoc_list = spoc_data;
      for (let i = 0; i < spoc_list.length; i++) {
        const element = spoc_list[i];
        this.selected_spocs.push(element.id);
      }
      this.selected_spocs = this.selected_spocs.join();
    },
    getCityClasificationCodes(city) {
      let url = `${constants.SERVER_API}getCityClasificationCodes?city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getCityClasificationCodes", response);

          this.city_classification_list = this.sortArrayAlphabetically(
            response.data
          );
          if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
             this.city_classification_list;
            }
            else{
            this.city_classification_list = this.city_classification_list.filter(item => item !== 'X');
           }
           
          if (localStorage.getItem("role") === "cm4") {

            this.selected_city_classification = this.city_classification_list[0];
          } else if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "ac1" ||
            localStorage.getItem("role") === "exed"
          ) {
            console.log("assigning the city classification check", this.loggedin);
            this.spoc_names.forEach((spoc) => {
              if (spoc.id === this.loggedin) {
                console.log("assigning the city classification", spoc.city_classification);
                this.selected_city_classification = spoc.city_classification;
              }
            });
          }
          console.log(
            this.city_classification_list,
            "city_classification_list"
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getrole(providedrole) {
      if (localStorage.getItem("role") === providedrole) {
        return true;
      } else {
        return false;
      }
    },
    preFlightFetch(level, placement, type, selected_city_classification) {
      let city_classification = selected_city_classification;
      if (type === "Allocated" || type === "Delays") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        this.fetchSelectedData(level, spocname, newtype, city_classification);
      } else {
        this.fetchSelectedData(level, placement, type, city_classification);
      }
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 2;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: [],
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 2) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 2) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    getstyle(data) {
      if (
        data.overall.contacted === "Total" ||
        data.level === "M3" ||
        data.level === "L6" ||
        data.level === "L1" ||
        data.level === "M4"
        // data.overall.contacted === "N/A"
      ) {
        return "1px solid";
      } else {
        return "";
      }
    },
    fetchSelectedData(level, placement, type, city_classification) {
      console.log(placement);
      let popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
      this.spoc_names.forEach((spoc) => {
        console.log("spoc", spoc);
        if (spoc.full_name === placement) {
          placement = spoc.id;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getDelaysByLevelContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          level: level,
          type: type,
          spoc_id: "",
          city: "",
          city_classification: "",
          selected_level_type: 'm_level',
        };
        if (obj.level == 'Grand Total') {
          obj.level = "total";
        }
        // if (obj.level == 'P1 & P2 Total') {
        //   obj.level = "P1_P2";
        // }
        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
          obj.city_classification = city_classification;
        }
        if (obj.level === "Total") {
          obj.level = "total";
        }
        console.log("finsskjkdjs", obj)
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("getDelaysByLevelContactDetails", response);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.$vs.loading.close();
            let popupcount = response.data.total;
            console.log("counnt", popupcount);
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getDelaysByLevelContactDetails",
              popupName + "-" + popupcount,
            ]);

          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpocList(selected_spocs) {
      console.log("selected spocs list step 1", selected_spocs);
      let selected_spocs_list = [];
      let team = localStorage.getItem('team')
      if (team == 'CM') {
        selected_spocs_list.push(localStorage.getItem('spoc_id'));
        selected_spocs_list = selected_spocs_list.join();
        return selected_spocs_list;
      } else {
        selected_spocs.forEach((spoc) => {
          if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
           selected_spocs_list.push(spoc.id);
          }else {
            if(spoc.city_classification != 'X'){
              selected_spocs_list.push(spoc.id);
            }
          }
        });
        selected_spocs_list = selected_spocs_list.join();
        return selected_spocs_list;
      }
    },
    updateValues() {
      console.log("this.selected_spocs-------", this.selected_spocs);
      this.$vs.loading();
      let level_obj = {
        "M Levels": "m_level",
        "U Levels": "u_level"
      }
      let obj = {
        selected_level_type: level_obj[this.selected_level],
        selected_date: this.datatoTimestamp(this.dateRange),
        spoc_ids: this.getSpocList(this.selected_spocs),
        pan_india: "",
      };
      if (this.selected_city == 'Pan India') {
        obj.pan_india = 'Yes';
      }
      console.log("updateValues dateRange", obj);
      axios
        .get(`${constants.SERVER_API}getDelaysByLevel`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("step2", response);

          // this.consoleLogTotals(response.data.data);
          if (response.data.status === "error") {
            this.handleNotification(response);
            this.$vs.loading.close();
          } else {
            console.log("this.tableRows fghh", response);
            this.generateDataTable(response.data.data);
            this.$vs.loading.close();
            }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    generateDataTable(response) {
      if (this.selected_level == "M Levels") {
        this.tableRows = this.tablerowConstant;
        this.generateTableHeaders(response);
        this.generateTableRows(response);
      } else if (this.selected_level == "U Levels") {
        EventBus.$emit("get-ulevel-data", response, this.selected_city_classification, this.selected_city, this.spoc_names);
      }

    },
    generateTableHeaders(response) {
      // console.log("asd", spoc_names);
      console.log("dealys", response);
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "OverAll",
        colspan: 1,
        fixed: true,
      });
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
        fixed: true,
      });
      if(this.selected_city != 'Pan India'){
        let city_code = this.selected_city_classification;
        let city = this.selected_city.concat(" ", "(", city_code, ")");
        this.tableHeaders.push({
          header: city,
          colspan: 2,
        });
      }else {
        this.tableHeaders.push({
          header: '',
          colspan: 2,
        });
      }
      if (this.selected_city == "Pan India") {
        response.forEach((cityData) => {
        let obj = {
          header: cityData.city,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
      }else{
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
    }
    },
    generateTableRows(response) {
      console.log('OOPP',this.tableRows, this.tablerowConstant);
      this.tableRows[0].dynamicSpoc = [];
      if(this.selected_city != 'Pan India'){
      this.tableRows[0].city = {
        allocated: "Allocated",
        delays: "Delays",
      };
      }else{
        this.tableRows[0].city = {}
      }
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          if(this.selected_city != 'Pan India'){
          row.city = {
            allocated: 0,
            delays: 0,
          };
        }
        }
      });
      console.log("jkfkjdkfjd222222", response)
      response.forEach(() => {
        // let allocated = 0;
        // let delays = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        // this.tableRows.forEach((row, index) => {
        //   if (index !== 0) {
        //     row.dynamicSpoc.push(allocated);
        //     row.dynamicSpoc.push(delays);
        //   }
        // });
      });
      this.InitialCalculation(response);
      console.log('oopp', response)
    },
    InitialCalculation(response) {

      this.tableRows.forEach((row, index) => {
        if (index > 0 && index !== this.tableRows.length - 1) {
          let cityallocated = 0;
          // let citycontacted = 0;
          let citydelays = 0;
          row.dynamicSpoc = [];
          response.forEach((spoc) => {

            for (var key in spoc) {
              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  for (var key2 in spoc[key]) {
                    if (spoc[key].hasOwnProperty(key2)) {
                      if (key2 === row.level) {
                        //city total column calculation
                        cityallocated =
                          cityallocated + spoc[key][key2].allocated;
                        citydelays = citydelays + spoc[key][key2].delays;
                        //individual calculation
                        row.dynamicSpoc.push(spoc[key][key2].allocated);
                        row.dynamicSpoc.push(spoc[key][key2].delays);
                      }
                    }
                  }
                }
              }
            }

          });
          //city total column calculation
          if(this.selected_city != 'Pan India'){
          row.city.allocated = cityallocated; //Row totals
          row.city.delays = citydelays; //Row totals
          }

        }
      });
      // let totalcityallocated = 0;
      // let totalcitydelays = 0;
      // let dynamicarr = [];

      // let p1_p2_delays_total = 0;
      // let p1_p2_allocated_total = 0;
      // let p1_p2_dynamic_array = 0;
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      // response.forEach((spoc) => {
      //   naallocated = naallocated + spoc.NA.allocated;
      //   nadelays = nadelays + spoc.NA.delays;
      //   nadynamic.push(spoc.NA.allocated);
      //   nadynamic.push(spoc.NA.delays);
      // });
      // this.tableRows[this.tableRows.length - 1].city.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 1].city.delays = nadelays;
      // this.tableRows[this.tableRows.length - 1].dynamicSpoc = nadynamic;
      // this.tableRows.forEach((row, index) => {
      //   if (index === 1) {
      //     dynamicarr = row.dynamicSpoc;
      //   } else if (index > 2) {
      //     totalcityallocated = totalcityallocated + row.city.allocated;
      //     totalcitydelays = totalcitydelays + row.city.delays;
      //     // totalcityallocated = row.city.allocated;
      //     // totalcitydelays = row.city.delays;
      //     // console.log(row.dynamicSpoc);
      //     row.dynamicSpoc.forEach((spocStat, index2) => {
      //       // dynamicarr[index2] =
      //       //   parseInt(dynamicarr[index2]) + parseInt(spocStat);
      //       dynamicarr[index2] = parseInt(spocStat);
      //     });
      //   }
      // });
      // this.tableRows[1].city.allocated = totalcityallocated;
      // this.tableRows[1].city.delays = totalcitydelays;
      // this.tableRows[1].dynamicSpoc = dynamicarr;
      // this.calculateM7();
      this.calculateTotals();
      this.$vs.loading.close();
    },
    calculateM7() {
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      // response.forEach((spoc) => {
      //   naallocated = naallocated + spoc.M7.allocated;
      //   nadelays = nadelays + spoc.M7.delays;
      //   nadynamic.push(spoc.M7.allocated);
      //   nadynamic.push(spoc.M7.delays);
      // });
      // this.tableRows[this.tableRows.length - 2].city.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 2].city.delays = nadelays;
      // this.tableRows[this.tableRows.length - 2].dynamicSpoc = nadynamic;
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.allocated = totalcityallocated;
      this.tableRows[1].city.delays = totalcitydelays;
      this.tableRows[1].dynamicSpoc = dynamicarr;
    },
    calculateTotals() {
      let parts = ["P1", "P2", "P3"];
      parts.forEach((eachPart) => {
        let cityAllocated = 0;
        let cityDelays = 0;
        let dynamicSpocValues = [];
        this.tableRows[0].dynamicSpoc.forEach(() => {
          dynamicSpocValues.push(0);
        });
        this.tableRows.forEach((tableRow) => {
          if(this.selected_city != 'Pan India'){
          if (tableRow.type === eachPart) {
            cityAllocated =
              parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
            cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          } else if (tableRow.type === `${eachPart} Total`) {
            tableRow.city.allocated = cityAllocated;
            tableRow.city.delays = cityDelays;
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }else{
          tableRow.city = {}
          if (tableRow.type === eachPart) {
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          }
          if (tableRow.type === `${eachPart} Total`) {
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }
        });
      });
      this.tableRows.forEach(tableRow => {
        if(this.selected_city != 'Pan India'){
        if (tableRow.overall === "Grand Total") {
          this.tableRows[6].dynamicSpoc.forEach((p1, p1_index) => {
            this.tableRows[9].dynamicSpoc.forEach((p2, p2_index) => {
              this.tableRows[12].dynamicSpoc.forEach((p3, p3_index) => {
              if (p1_index === p2_index && p2_index === p3_index) {
                tableRow.dynamicSpoc.push(p1 + p2 + p3);
              }
            })

            });
          });
          this.tableRows[13].city.allocated = this.tableRows[6].city.allocated + this.tableRows[9].city.allocated + this.tableRows[12].city.allocated;
          this.tableRows[13].city.delays = this.tableRows[6].city.delays + this.tableRows[9].city.delays + this.tableRows[12].city.delays;
        }
        }else{
          if (tableRow.overall === "Grand Total") {
          this.tableRows[6].dynamicSpoc.forEach((p1, p1_index) => {
            this.tableRows[9].dynamicSpoc.forEach((p2, p2_index) => {
              this.tableRows[12].dynamicSpoc.forEach((p3, p3_index) => {
              if (p1_index === p2_index && p2_index === p3_index) {
                tableRow.dynamicSpoc.push(p1 + p2 + p3);
              }
            })

            });
          });
        }
        }
      });
      // this.tableRows.forEach(tableRow => {
      //   if (tableRow.overall === "P1 & P2 Total") {
      //     this.tableRows[7].dynamicSpoc.forEach((p1, p1_index) => {
      //       this.tableRows[10].dynamicSpoc.forEach((p2, p2_index) => {
      //         if (p1_index == p2_index) {
      //           tableRow.dynamicSpoc.push(p1 + p2);
      //         }
      //       });
      //     });
      //     this.tableRows[2].city.allocated = this.tableRows[7].city.allocated + this.tableRows[10].city.allocated;
      //     this.tableRows[2].city.delays = this.tableRows[7].city.delays + this.tableRows[10].city.delays;
      //   }
      // });

      console.log("findall data", this.tableRows)
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getSpocs() {
      this.$vs.loading()
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;
      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cm4"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // Uncommented this
          this.extractSpocByCity(response.data.spocs);
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close()
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      if(localStorage.getItem('team') != 'GM' && localStorage.getItem("role") === "cm4"){
        this.city_options = ["Pan India"].concat(this.sortArrayAlphabetically(this.city_options));
      }
      // this.cities.push("Pan India");
      // this.city_classification_list = this.sortArrayAlphabetically(this.city_classification_list);
      if ((localStorage.getItem("role") === "cm4" && localStorage.getItem("team") != "GM") || this.logged_in_team == 'Admin') {
        this.selected_city = this.city_options[0];
      }
      else if (localStorage.getItem("team") == "GM") {
          this.city_options = []
          this.city_options = this.cities;
          this.selected_city = this.city_options[0];
      }
      else if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "exed"
      ) {
        this.spoc_names.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
      // this.getCityClasificationCodes(this.selected_city);
      // this.updateValues();
    },
  },
  components: {
    DateRangePicker,
    Datepicker,
    VueApexCharts,
    "v-select": vSelect,
    CoverageULevelsTable
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.fixed_columns_section {
  left: 0px;
  position: sticky;
  z-index: 100;
  background: white;
}

.fixed_header_level_section {
  left: 60px;
  position: sticky;
  z-index: 100;
  width: 100px;
  /* background: white; */
  /* border: 1px solid black; */
}
.fixed_header_level1_section{
  left: 80px;
  position: sticky;
  z-index: 100;
  width: 100px;
}
.fixed_first_row_section {
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 100000;
}
</style>
