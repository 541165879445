<template>
  <div>
    <vs-table :data="tableRows" style="margin-top: 3%; border-top: 1px solid; border-left: 1px solid; color: black" max-height="500px">
      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan" style="border-right: 1px solid; position: sticky;
                                  top: 0px; background-color: white;"
          :class="{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Level' }">{{
            head.header }}</vs-th>
      </template>
      <template slot-scope=" { data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style" :class="indextr === 0 ? 'fixed_first_row_section' : ''">
          <vs-td class="fixed_columns_section" :style="tr.style">{{ tr.overall }}</vs-td>
          <vs-td class="fixed_header_level_section" :style="tr.style">{{ tr.level }}</vs-td>
          <vs-td :style="[selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'allocated',
                selected_city_classification,

              )
            ">
              {{ tr.city.allocated }}
            </div>
          </vs-td>
          <vs-td :style="[selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'delays',
                selected_city_classification,
              )
            ">
              {{ tr.city.delays }}
            </div>
          </vs-td>
          <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" style="border: 1px solid">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                [tr, spocIndex],
                data[0].dynamicSpoc[spocIndex],
                selected_city_classification,

              )
            ">
              {{ spoc }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
  
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus";
import DelaysMAccPopup from "./DelaysMAccPopup.vue";
export default {
  mounted() {
    EventBus.$on("get-ulevel-data", (response, cc, city, spoc_names) => {
            this.selected_city_classification = cc;
      this.selected_city = city;
      this.spoc_names = spoc_names;
      this.generateDataTable(response);
    });

  },
  components: {
    Datepicker,
    "v-select": vSelect,
    DelaysMAccPopup,
  },
  watch: {
    dateRange() {
      this.getMAccLevelsCount();
    },
  },
  data() {
    return {
      dateRange: new Date(),
      MwbLeads: [],
      totalCount: 0,
      open: false,
      level: "",
      placement_data: "",
      type_data: "",
      selected_cc: "",
      title: "",
      currentTablePage: 1,
      tablelinks: 1,
      spoc_names: [],
      selected_city_classification: "",
      selected_city: "",
      macc_id: [],
      tableRows: [],
      tableHeaders: [],
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      IIMLLevelsTooltip:
        // "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout, CMYTC - CM-Yet-To-Connect",
        "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout",
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablerowULevelConstant: [
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid", background: "#ffffff" },
          overall: "",
          level: ""
        },
        {
          style: { borderBottom: "1px solid", background: "#FFCCCC" },
          overall: "Total",
          level: ""
        },
        {
          style: { background: "#ccffcc" },
          level: "U0",
          rowspan: 4,
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U1",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U1+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U2",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3++",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4R",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U5",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U5+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U7",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U7-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U9",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U10",
          type: "P1"
        },
        {
          style: { borderTop: "1px solid", background: "#FFCCCC", borderBottom: "1px solid" },
          overall: "Total",
          level: ""
        },
      ],
    };
  },
  methods: {
    generateDataTable(response) {
      this.tableRows = this.tablerowULevelConstant;
      this.generateTableHeaders(response);
      this.generateTableRows(response);
    },
    generateTableHeaders(response) {
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "OverAll",
        colspan: 1,
        fixed: true,
      });
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
        fixed: true,
      });
      if(this.selected_city != 'Pan India'){
      let city_code = this.selected_city_classification;
      let city = this.selected_city.concat(" ", "(", city_code, ")");
      this.tableHeaders.push({
        header: city,
        colspan: 2,
      });
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
    }else {
        this.tableHeaders.push({
          header: '',
          colspan: 2,
        });
        response.forEach((cityData) => {
        let obj = {
          header: cityData.city,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
      }
      // response.forEach((cityData) => {
      //   let obj = {
      //     header: cityData.spoc_name,
      //     colspan: 2,
      //   };
      //   this.tableHeaders.push(obj);
      // });
    },
    generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      if(this.selected_city != 'Pan India'){
      this.tableRows[0].city = {
        allocated: "Allocated",
        delays: "Delays"
      };
      }else{
        this.tableRows[0].city = {}
      }
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          if(this.selected_city != 'Pan India'){
          row.city = {
            allocated: 0,
            delays: 0
          };
        }else{
          row.city = {};
        }
      }
      });
      response.forEach(() => {
        let allocated = 0;
        let delays = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc.push(allocated);
            row.dynamicSpoc.push(delays);
          }
        });
      });
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      this.tableRows.forEach((row, index) => {

        if (index > 1 && index !== this.tableRows.length - 1) {
          let cityallocated = 0;
          let citydelays = 0;
          row.dynamicSpoc = [];
          response.forEach(spoc => {
            for (var key in spoc) {

              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  for (var key2 in spoc[key]) {
                    if (spoc[key].hasOwnProperty(key2)) {
                      if (key2 === row.level) {
                        cityallocated =
                          cityallocated + spoc[key][key2].allocated;
                        citydelays = citydelays + spoc[key][key2].delays;
                        row.dynamicSpoc.push(spoc[key][key2].allocated);
                        row.dynamicSpoc.push(spoc[key][key2].delays);
                      }
                    }
                  }
                }
              }
            }
          });
          if(this.selected_city != 'Pan India'){
          row.city.allocated = cityallocated;
          row.city.delays = citydelays;
          }
        }
      });
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] = parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.allocated = totalcityallocated;

      this.tableRows[1].city.delays = totalcitydelays;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      this.calculateM7(response);
      this.calculateTotals();
      let last_row = this.tableRows.length - 1;
      if(this.selected_city != 'Pan India'){
      this.tableRows[last_row].city.allocated = totalcityallocated;
      this.tableRows[last_row].city.delays = totalcitydelays;
      }else{
        this.tableRows[last_row].city = {}
      }
      this.tableRows[last_row].city.dynamicSpoc = dynamicarr;
      this.$vs.loading.close();
    },
    calculateM7(response) {
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.allocated = totalcityallocated;
      this.tableRows[1].city.delays = totalcitydelays;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      let last_row = this.tableRows.length - 1;
      if(this.selected_city != 'Pan India'){
      this.tableRows[last_row].city.allocated = totalcityallocated;
      this.tableRows[last_row].city.delays = totalcitydelays;
      }else{
        this.tableRows[last_row].city = {}
      }
      this.tableRows[last_row].dynamicSpoc = dynamicarr;
      console.log(response)
    },
    calculateTotals() {
      let parts = ["P1", "P2", "P3"];
      parts.forEach(eachPart => {
        let cityAllocated = 0;
        let cityDelays = 0;
        let dynamicSpocValues = [];
        this.tableRows[0].dynamicSpoc.forEach(() => {
          dynamicSpocValues.push(0);
        });
        this.tableRows.forEach(tableRow => {
          if(this.selected_city != 'Pan India'){
          if (tableRow.type === eachPart) {
            cityAllocated =
              parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
            cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          } else if (tableRow.type === `${eachPart} Total`) {
            tableRow.city.allocated = cityAllocated;
            tableRow.city.delays = cityDelays;
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }else{
          tableRow.city = {}
          if (tableRow.type === eachPart) {
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          }
          if (tableRow.type === `${eachPart} Total`) {
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }
        });
      });
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 2;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: []
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 2) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 2) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    countDeails(level, placement, type, selected_city_classification) {
      let city_classification = selected_city_classification;
      if (type === "Allocated" || type === "Delays") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        // this.level = level,
        // this.type_data = newtype;
        // this.selected_cc = city_classification
        this.getDetailsData(level, spocname, newtype, city_classification);
      } else {
        // this.level = level,
        // this.type_data = type;
        // this.selected_cc = city_classification
        this.getDetailsData(level, placement, type, city_classification);
      }
    },
    getDetailsData(level, placement, type, city_classification) {
      console.log(placement);
      // this.title = `${level} - ${placement} - ${type}`;
      let popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
      this.spoc_names.forEach((spoc) => {
        if (spoc.full_name === placement) {
          placement = spoc.id;
          this.placement_data = placement;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getDelaysByLevelContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          level: level,
          type: type,
          spoc_id: "",
          city: "",
          city_classification: "",
          selected_level_type: 'u_level',
        };

        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
          obj.city_classification = city_classification;
        }
        if (obj.level === "Total") {
          obj.level = "U";
        }
        console.log("finsskjkdjs", obj)
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("getDelaysByLevelContactDetails", response);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);

            let popupcount = response.data.total;
            console.log("counnt", popupcount);
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getDelaysByLevelContactDetails",
              popupName + "-" + popupcount,
            ]);
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
  
<style scoped>
  .vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.fixed_columns_section {
  left: 0px;
  position: sticky;
  z-index: 100;
  background: white;
}

.fixed_header_level_section {
  left: 60px;
  position: sticky;
  z-index: 100;
  width: 100px;
}
.fixed_first_row_section {
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 1000000;
}
</style>